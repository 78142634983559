<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="12" class="font260 text-center" style="margin-top: 20px">
          กำลังตรวจสอบสิทธิ์การเข้าถึง......</CCol>

      </CRow>
    </CContainer>
    <!--- MODAL AREA-->
    <CModal :show.sync="darkModal" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
      color="dark">
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>

  </div>
</template>

<script>
import SltAsset from "../components/SltAsset";
import TblAllAsset from "../components/TblAllAsset";
export default {
  name: "Login",
  components: {
    SltAsset,
    TblAllAsset
  },
  data() {
    return {
      darkModal: false,
      btnDisable: false,
    };
  },
  mounted() {
    let params = this.$route.params;
    const formData = new FormData();
    formData.append("txtAction", "get");
    formData.append("txtUsername", params.username);
    formData.append("txtPassword", params.pw);
    this.axios
      .post(this.$hostUrl + "php_action/userAPI.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {

        //let strJson = (response.data).toString();
        if (!response.data.userData) {
          alert("สิทธิ์การใช้งานไม่ถูกต้อง");
          return false;
        }
        if (response.data.error == true) {
          this.darkModal = true;
        } else {
          this.$cookies.set("user", response.data.userData);
          //this.$store.commit('setUserData', this.$cookies.get("user"));
          this.$router.push({ name: "Home" });
        }
      });

  },
  methods: {
    booking(event) {
      //console.log(this.$store.state.selectedAsset.length);
      if ((this.$store.state.selectedAsset).length == 0) {
        alert("กรุณาระบุช่วงเวลา");
        return false;
      }
      this.btnDisable = true;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("assetId", this.$store.state.selectedAsset.code);

      this.axios
        .post(this.$hostUrl + "php_action/reservationAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.createState == 'created') {
            alert("บันทึกสำเร็จ");
            location.reload();
          }
          else {
            alert(response.data.error);
          }
        });
    },

    roughSizeOfObject(object) {
      var objectList = [];
      var stack = [object];
      var bytes = 0;

      while (stack.length) {
        var value = stack.pop();

        if (typeof value === "boolean") {
          bytes += 4;
        } else if (typeof value === "string") {
          bytes += value.length * 2;
        } else if (typeof value === "number") {
          bytes += 8;
        } else if (
          typeof value === "object" &&
          objectList.indexOf(value) === -1
        ) {
          objectList.push(value);

          for (var i in value) {
            stack.push(value[i]);
          }
        }
      }
      return bytes;
    },
  },
};
</script>
